import React from "react"
import { graphql } from "gatsby"
import moment from "moment"

import Layout from "../components/layout"
import Seo from "../components/seo"

const DefaultPage = ({ data }) => {
	const page = data.markdownRemark
	const page_title = page.frontmatter.title
  const tz_offset = new Date().getTimezoneOffset()
	const page_date = moment(new Date(page.frontmatter.date)).utcOffset(tz_offset)
	const page_slug = page.frontmatter.slug.replace('/', '')

	return (
		<Layout>
      <Seo title={ page_title } slug={page_slug} />
      <header className="bg-azure">
        <div className="container mx-auto px-4 py-16">
          <h1 className="text-4xl">{ page_title }</h1>
        </div>
      </header>
      <main>
      	<div className="container mx-auto px-4 py-16">
      		<div className="markdown-wrapper" dangerouslySetInnerHTML={{ __html: page.html }} />
          <div className="mt-12 italic">
            Last updated on { page_date.format("MMM D, YYYY h:m:A") }
          </div>
      	</div>
      </main>
    </Layout>  
	)
}

export default DefaultPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        slug
        title
      }
    }
  }
`